import { onReady } from './utils/on-ready';
import { onWindowResize } from './utils/window-resize';
import { isTouch, initVhUnitOverwrite } from './utils/helpers';
import { initAnchorScroll } from './utils/scroll';
import { setupNavigation } from './layout/navigation';
import { calculateIframeSize } from './components/iframe-resize';
import { setupYoutubeVideos } from './components/youtube-video';
import { setupVimeoVideos } from './components/vimeo-video';
import { setupSwipers } from './components/swiper';
// import { setupThemeSwitcher } from './components/theme-switcher';

function init() {

    setTimeout(() => {
        document.body.classList.remove('standby');
    }, 0);

    if (isTouch) {
        document.body.classList.add('touch-device');
    }

    onReady(() => {
        initVhUnitOverwrite(calculateIframeSize);
        initAnchorScroll();
        setupNavigation();
        setupSwipers();
        setupVimeoVideos();

        window.addEventListener('CookieInformationConsentGiven', function() {
            // eslint-disable-next-line no-undef
            if (CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
                setupYoutubeVideos();
            }
        }, false);

        // Only use this during test-mode c'')
        // setupThemeSwitcher();
    });

    onWindowResize(() => {
        calculateIframeSize();
    });
}

init();
